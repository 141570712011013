import URLs from '@/urls';
import { EntityBase } from '@/commons/EntityBase.js';
import { isPresent } from '@/components/Utils/utils';
import constants from '@/commons/constants';
import utils from '@/components/Utils/utils';


export class AgentGroupEntity extends EntityBase {
    apiURLs = {
        listAll: URLs.AGENT_GROUP,
        save: URLs.AGENT_GROUP,
        update: URLs.AGENT_GROUP,
        delete: URLs.AGENT_GROUP,
        sync: URLs.AGENT_GROUP_SYNC
    };

    createEntityObj(entity = null) {
        let returnObj = {};

        returnObj.id = isPresent(entity) && isPresent(entity.id) ? entity.id : '';
        returnObj.name = isPresent(entity) && isPresent(entity.name) ? entity.name : '';
        returnObj.comment = isPresent(entity) && isPresent(entity.comment) ? entity.comment : '';
        returnObj.active = isPresent(entity) && isPresent(entity.active) ? entity.active : 'Y';
        returnObj.agents = isPresent(entity) && isPresent(entity.agents) ? entity.agents : [];

        return returnObj;
    }

    createEntityObjSaveEdit(entity = null) {
        let createEntityObj = this.createEntityObj(entity);
        return createEntityObj;
    }

    syncAgents(entity, agents) {
        this.log.debug('EntityBase of ' + this.constructor.name + ' - sync');
        let url = this.apiURLs.sync;
        
        if (isPresent(entity.id)) {
            let agentGroupId = entity.id; 
            url = eval(`\`${this.apiURLs.sync}\``);
        }

        return utils.apiCaller(constants.HTTP_METHODS.PUT, url, agents)
    }
}
