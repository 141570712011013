
import AgentList from '@/components/Agents/AgentList';
import DialogBase from '@/commons/DialogBase';
import { AgentGroupEntity } from '@/components/AgentGroups/AgentGroupEntity';


export default {
    name: 'AgentGroupDetails',
    extends: DialogBase,
    components: {
        AgentList,
    },
    props: {
        value: {
            type: Object,
            required: true
        },
        entity: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            form: {
                general: true,
                table: true,
            },
            partner: {
                list: [],
                showDetails: null,
                options: [],
                selected: {
                },
            },
            entityHandler: new AgentGroupEntity(this.$log, this.$store),
        }
    },

    created() {
    },

    computed: {
        isEdit() {
            return this.value.edit;
        },
        isOpenDetails() {
            return this.value.details;
        }
    },

    methods: {
        saveEditedEntity() {
            this.$log.debug('AgentGroup of ' + this.constructor.name + ' - saveEditedEntity');
            if (this.$refs.form.validate()) {
                this.$log.debug(this.editedEntity);
                const editedEntity = this._saveEditedEntityCreateEntityObj(this.editedEntity);

                this.entityHandler
                    .updateEntity(editedEntity, false)
                    .then((response) => {
                        let agents = {agents: this.editedEntity.agents.map(el => ({id: el.id}))};

                        this.entityHandler
                        .syncAgents(editedEntity, agents)
                        .then((response) => {})
                        .catch(error => {
                            this.$log.error(error);
                        })
                        .finally(() => { });

                        this.$store.commit('setStatusCode', response.status);
                        this.$store.commit('setSnackbarEnabled', true);
                        this.$parent.fetchEntities();
                        this.closeDialog();
                    })
                    .catch(error => {
                        this.$log.error(error);
                    })
                    .finally(() => { });
            } else {
                const invalidField = this.$refs.form.inputs.filter(item => item.valid === false);
                if (invalidField) {
                    this.$nextTick(() => invalidField[0].focus());
                }
                this.$store.commit('setStatusCode', 'invalidFormData');
                this.$store.commit('setSnackbarEnabled', true);
            }
        },

        togglePartnerDetails() {
            if (this.partner.showDetails == null) {
                this.initPartners();
                this.partner.showDetails = 0;
            } else {
                this.partner.showDetails = null;
            }
        },

        openDialogDetails(entity) {
            this.value.edit = true;
            this.value.details = true;
            this.loadEntityData(entity);
        },

        loadEntityData(entity) {
            this.entityHandler.getEntity(entity).then(entityData => {
                this.editedEntity = this.entityHandler.createEntityObj(entityData);
            });
        },
    },

    watch: {
        isOpenDetails() {
            if (this.isOpenDetails) {
                this.form.general = true;
                this.form.table = true;
                if (this.isEdit) {
                    this.openDialogDetails(this.entity);
                } else {
                    this.openDialogNew(this.entity);
                }

                //this.$nextTick(() => this.$refs.agent_form_firm_name.focus());
            }
        },
    }

}
