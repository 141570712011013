import DataComponentBase from '@/commons/DataComponentBase';
import AgentGroupDetails from '@/components/AgentGroups/AgentGroupDetails';
import { AgentGroupEntity } from '@/components/AgentGroups/AgentGroupEntity';
import VerificationDialog from '@/components/CustomVerificationDialog';


export default {
    name: 'AgentGroupList',
    extends: DataComponentBase,
    components: {
        AgentGroupDetails,
        VerificationDialog
    },
    props: {
        actions: {
            type: Boolean,
            default: true,
        },
        checkboxes: {
            type: Boolean,
            default: false,
        },
        partnerId: {
            type: String,
            default: null,
        }
    },

    data() {
        return {
            // tableItems: [],
            headerFilters: {
                id: "",
                active: "all",
                //   firmName: "",
                name: "",
                comment: "",
            },
            // selectedItem: {},
            // dialog: {
            //   details: false,
            //   edit: false,
            //   delete: false
            // },
            entityHandler: new AgentGroupEntity(this.$log, this.$store),
        }
    },

    // created() {
    //   this.initAgentGroupList();
    // },
    // mounted() {
    // },

    computed: {
        headers() {
            let headers = [
                {
                    text: this.$i18n.t('common.id'),
                    align: "right",
                    value: "id",
                    class: "width-89",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['id'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t("common.status"),
                    value: "active",
                    class: "width-105",
                    filterType: "selectSimple",
                    items: [
                        { text: this.$i18n.t("common.all"), value: "all" },
                        { text: this.$i18n.t("common.active"), value: "Y" },
                        { text: this.$i18n.t("common.inactive"), value: "N" },
                    ],
                    filter: f => {
                        if (this.headerFilters['active'] != "all") {
                            return f == this.headerFilters['active'];
                        } else {
                            return true;
                        }
                    }
                },
                {
                    text: this.$i18n.t('common.name'),
                    align: "left",
                    value: "name",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['name'].toLowerCase()) }
                },
                {
                    text: this.$i18n.t('common.comment'),
                    align: "left",
                    value: "comment",
                    filterType: "text",
                    filter: f => { return (f + '').toLowerCase().includes(this.headerFilters['comment'].toLowerCase()) }
                },
            ];

            if (this.actions) {
                headers.push(
                    {
                        text: "",
                        value: "actions",
                        sortable: true,
                        align: "center",
                        class: "action-column-2",
                    }
                )
            }

            return headers;
        },

    },

    methods: {
        //   initAgentGroupList() {
        //     this.tableItems = require('@/data/agentGroups.json').data;
        //     if(this.partnerId != null) {
        //       this.tableItems = this.tableItems.filter(el => {
        //         return el.partnerId == this.partnerId;
        //       });
        //     }
        //   },

        //   openDialogNew() {
        //     this.selectedItem = {};
        //     this.dialog.edit = false;
        //     this.dialog.details = true;
        //   },
        //   openDialogDetails(item) {
        //     this.selectedItem = JSON.parse(JSON.stringify(item));
        //     this.dialog.edit = true;
        //     this.dialog.details = true;
        //   },
        //   openDeleteDialog(item) {
        //     this.selectedItem = JSON.parse(JSON.stringify(item));
        //     this.dialog.delete = true;
        //   },
        //   closeDialog() {
        //     this.dialog.details = false;
        //     this.dialog.edit = false;
        //     this.dialog.delete = false;
        //     this.initAgentGroupList();
        //   },

        //   deleteAgentGroup(id) {
        //   },

        getStatus(isActive) {
            switch (isActive) {
                case "Y":
                    return { color: "success", text: "common.active" };
                    break;
                case "N":
                    return { color: "#525252", text: "common.inactive" };
                    break;
                default:
                    return { color: "error", text: "common.na" };
                    break;
            }
        },

    },

    watch: {
        //   'dialog.details'() {
        //     if(!this.dialog.details) {
        //       this.closeDialog();
        //     }
        //   },
    }

}
